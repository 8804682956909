import React, { useEffect, useState } from 'react'
import styled from 'styled-components'


const BlogBanner = ({ blogItem }) => {
  const [imageSrc, setImageSrc] = useState()

  useEffect(() => {
    const picPreUrl = 'https://blog.aspidanet.com/wp-json/wp/v2/media/'
    if (blogItem && blogItem.featured_media) {
      const getData = async () => {
        fetch(picPreUrl + blogItem.featured_media, { method: 'get', })
          .then(e => e.json())
          .then((res) => {
            if (res && res.guid.rendered) {
              // console.log(res.guid.rendered)
              setImageSrc(res.guid.rendered)
            }
          })
          .catch(e => console.log("Oops, error", e))
      }
      getData()
    }
  }, [blogItem])


  return (
    <>
      <StyledBlogBanner>
        <img src={imageSrc} alt='' />
      </StyledBlogBanner>
    </>
  )
}
export default BlogBanner


const linear_gradient = 'linear-gradient(113deg, #FB7C56 1.48%, #FF3585 100%)'
const StyledBlogBanner = styled.div`
  width: 384px;
  height: 216px;
  border-radius: 16px;
  overflow: hidden;
  background: ${linear_gradient};
  @media (max-width: 1200px) {
    width: 100%;
    height: 190px;
  }
  img {
    width: 100%;
    height: 100%;
  }
`


