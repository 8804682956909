const zh_CN = {
  SecurityAudit_sub: 'Your assets are audited and secured by leading blockchain experts to ensure utmost safety.',
  SecurityAudit: 'Security Audit',
  Stake_With_Aspida: 'Restake with Aspida',
  Stake_With_Aspida_sub: 'Start restaking now to unlock daily rewards.',
  JoinUs: 'Join Us',
  JoinUs_sub: 'Thanks to our dedicated partners and the leading builders in the DeFi industry.',
  Blog: 'Blog',
  Partners: 'Partners and stakeholders',
  Blog_sub: 'Explore the world of Aspida',
  Optimize_your_yield: 'Optimize your yield with Aspida',
  Aspida: 'Aspida',
  Aspida_subTitle: 'Revolutionizing DeFi with Liquid Restaking',
  Aspida_footer_subTitle:'Accessible, Efficient, and Multichain Liquid Restaking Infrastructure.',
  Launch_APP: 'Stake Now',
  Features: 'Features',
  Features_SubTitle: 'Aspida is a liquid restaking infrastructure designed to enhance Liquidity Restaking Tokens across major cryptocurrencies.',
  Feature1: 'Security and Expertise',
  Feature2: 'Protocol-Own-Liquidity',
  Feature3: 'Multichain Interoperability',
  Feature1_text: 'The Aspida team possesses extensive domain expertise in Proof of Stake (PoS) networks and validation, with over 7 years of experience in PoS network validation.',
  Feature2_text: 'The protocol is designed to maximize capital efficiency while internalizing liquidity via protocol-own-liquidity module, to provide secondary market liquidity via different liquidity venues.',
  Feature3_text: 'Aspida is committed to building customized cross-chain features to enable seamless cross-chain interoperability, facilitating greater flexibility and accessibility across multiple blockchain networks.',
  Community: '社区',
  Twitter: 'Twitter',
  Medium: 'Medium',
  Telegram: '电报群',
  WeChat: '微信',
  Stake: 'Mint and Receive',
  Stake_text: 'Connect your wallet to Aspida to mint ETH and receive aETH.',
  Earn_text: 'Deposit aETH as collateral to mint saETH and earn staking rewards in real-time.',
  Earn: 'Stake and Earn',
  Use_in_DeFi: 'Use in DeFi',
  Use_in_DeFi_text: 'Obtain aETH in your wallet and use them in DeFi protocols for extra yield.',
  // Legendary
  sloganTitle:'Welcome to the Legendary Journey',
  sloganSubTitle:'The Legendary Journey is a points-based campaign prepared for the Aspida Mainnet Launch, comprising a range of tasks.You will have the opportunity to discover innovative forms of liquidity staking,explore new LSDFi primitives, and earn rewards along the way!',
}
export default zh_CN
