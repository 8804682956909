import * as React from 'react';
import { useState } from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  Link
  // NavLink,
} from "react-router-dom";
import "@arco-design/web-react/dist/css/arco.css";
// import styled from 'styled-components';
import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import en_US from "./i18n/en_US.js";
import zh_CN from "./i18n/zh_CN.js";

import styled from 'styled-components'
import Snowflakes from './components/Snowflakes'

import Home from './components/Home.jsx'
import Shields from './components/Shields.jsx'

// 中英翻译
const messages = {
  en: en_US,
  cn: zh_CN,
};
const local_lauguage = window.localStorage.getItem("pos-language");
const initialLocale = local_lauguage || "en";
const cache = createIntlCache();

let intl = createIntl(
  {
    locale: initialLocale,
    defaultLocale: "en",
    messages: messages[initialLocale],
  },
  cache
);
const App = () => {
  // 中英翻译
  const [locale, setLocale] = useState(initialLocale);

  const [menu, setMenu] = useState(false)

  const showMenu = () => {
    setMenu(!menu)
  }

  const OutLink = {
    stake_now: 'https://app.aspidanet.com/',
    Documents: 'https://docs.aspidanet.com/',
    Blog: 'https://blog.aspidanet.com/',
    Twitter: 'https://twitter.com/aspidanet',
    Discord: 'https://discord.com/invite/UrYn3KjagY',
    Telegram: 'https://t.me/aspida_net',
    SeeReport: 'https://t.me/aspida_net',
    Github: 'https://github.com/aspidanet',
    Medium: 'https://medium.com/@aspidabd',
    Forum: '',
    FAQ: '',
  }
  const changeLanguage = newLocale => {
    intl = createIntl(
      { locale: newLocale, defaultLocale: "en", messages: messages[newLocale] },
      cache
    );
    console.log(newLocale);
    window.localStorage.setItem("pos-language", newLocale);
    document.documentElement.lang = newLocale;
    setLocale(newLocale);
  };
  return (
    <Router>
      <RawIntlProvider value={intl}>
      <Container>
        <Snowflakes className="wrapper"></Snowflakes>

        <TopBar>
          <Log src="/images/logo.svg" />
          <Nav>
            <li>
              <a><Link to="/">Home</Link></a>
            </li>
            <li>
              {/* <a><Link to="/Shields">Shields</Link></a> */}
              <a href='https://bridge.aspidanet.com' target="_blank" rel="noreferrer">Bridge</a>
            </li>
            <li>
              <a href={OutLink.Documents} target="_blank" rel="noreferrer">Documents</a>
            </li>
            {/* <li>
              <a href={OutLink.FAQ} target="_blank" rel="noreferrer">FAQ</a>
            </li> */}
            <li>
              <a href={OutLink.Blog} target="_blank" rel="noreferrer">Blog</a>
            </li>
            <li>
            <ChangeLanguage
            // onBlur={() => { buttonOnBlur() }}
            // onClick={() => { setOpen(!open) }}
            >
              <span>Community</span>
              <Changelist className={'showList'}>
                <a href={OutLink.Telegram} target="_blank" rel="noopener noreferrer">Telegram</a>
                <a href={OutLink.Discord} target="_blank" rel="noopener noreferrer">Discord</a>
              </Changelist>
            </ChangeLanguage>
            </li>
            <JoinT>
              <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">
                <img src="/images/Join-Twitter.svg" alt="" />
                <span>Join Twitter</span>
              </a>
            </JoinT>
          </Nav>
        </TopBar>
        <MtopBar>
          <MLeft>
            <Log src="/images/logo.svg" />
          </MLeft>
          <MRight>
            <JoinT>
              <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">
                <img src="/images/Join-Twitter.svg" alt="" />
                <span>Join Twitter</span>
              </a>
            </JoinT>
            <Menu onClick={showMenu}>
              <img src="/images/m-menu.svg" alt="" />
            </Menu>
          </MRight>
        </MtopBar>
        <Switch>
          <Route exact path="/">
            <Home changeLanguage={changeLanguage} locale={locale} />
          </Route>
          {/* <Route exact path="/Shields">
            <Shields changeLanguage={changeLanguage} locale={locale} />
          </Route> */}
          <Route path="*">
            <Redirect to={'/'}></Redirect>
          </Route>
        </Switch>
        </Container>
        <Mmenu className={menu ? 'show' : ''} onClick={showMenu}>
        <Mnav>
          <a><Link to="/">Home</Link></a>
          {/* <a><Link to="/Shields">Shields</Link></a> */}
          <a href='https://bridge.aspidanet.com' target="_blank" rel="noreferrer">Bridge</a>
          <a href={OutLink.Documents} target="_blank" rel="noreferrer">Documents</a>
          {/* <a href={OutLink.FAQ} target="_blank" rel="noreferrer">FAQ</a> */}
          <a href={OutLink.Blog} target="_blank" rel="noreferrer">Blog</a>
          <a href={OutLink.Telegram} target="_blank" rel="noopener noreferrer">Telegram</a>
          <a href={OutLink.Discord} target="_blank" rel="noopener noreferrer">Discord</a>
        </Mnav>
      </Mmenu>
      </RawIntlProvider>
    </Router>
  )
};

const linear_gradient = 'linear-gradient(113deg, #FB7C56 1.48%, #FF3585 100%)';
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  background-image: url('/images/body-bg.svg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  @media (max-width: 1200px) {
    width: 100vw;
    height: auto;
    position: inherit;
    left: 0;
    transform: translateX(0);
    background-size: 170%;
  }
`;
const TopBar = styled.header`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  /* background-color: red; */
  @media (max-width: 1200px) {
    display: none;
  }
`;
const Log = styled.img`
  width: 157px;
  height: 31px;
  @media (max-width: 1200px) {
    width: 78px;
    height: 15px;
  }
`;
const ChangeLanguage = styled.button`
  cursor: pointer;
  position: relative;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  span{
    line-height: 40px;
    font-weight: 400;
  }
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  position: absolute;
  left: 50%;
  top: 36px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 8px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  a {
    line-height: 36px;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  li {
    margin-right: 24px;
    display: flex;
    align-items: center;
    a {
      color: #fff;
    }
  }
`;
const JoinT = styled.div`
  border-radius: 8px;
  background: ${linear_gradient};
  a {
    display: flex;
    padding: 8px 12px;
    align-items: center;
  }
  img {
    width: 14px;
    margin-right: 8px;
  }
  span {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: 1200px) {
    a {
      padding: 6px 12px;
    }
    img {
      width: 10px;
      margin-right: 4px;
    }
    span {
      font-size: 11px;
    }
  }
`;
const MtopBar = styled.header`
  display: none;
  @media (max-width: 1200px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 12px;
  }
`;
const MLeft = styled.header`
  @media (max-width: 1200px) {
    img {
      height: 30px;
      width: 100px;
    }
  }
`;
const MRight = styled.header`
  @media (max-width: 1200px) {
    display: flex;
  }
`;
const Menu = styled.div`
  width: 26px;
  height: 26px;
  margin-left: 8px;
  /* background-color: red; */
  img {
    width: 26px;
    height: 26px;
  }
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    background-color: #1C1C20;
    width: 140px;
    position: absolute;
    right: 12px;
    top: 40px;
    padding: 4px 16px;
    border-radius: 8px;
    a {
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1200px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #00000099;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
export default App;