/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import Snowflakes from './Snowflakes'
import { format_num_to_K,formatNumber } from './utils/index.js'
import BlogBanner from './BlogBanner'
import {
  Tooltip,
} from "@arco-design/web-react";



const Home = ({ changeLanguage, locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  // const ref = createRef(null)
  // const [open, setOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [BlogData, setBlogData] = useState([])
  const [totalData, setTotalData] = useState({})


  useEffect(() => {
    const api = 'https://blog.aspidanet.com/wp-json/wp/v2/posts'
    const api_total_data = 'https://api.aspidanet.com/page_data/?network=mainnet'

    const getData = async () => {
      fetch(api, { method: 'get', })
        .then(e => e.json())
        .then((res) => {
          if (res && res instanceof Array && res.length > 3) {
            const sliceArr = res.slice(0, 3)
            // console.log(sliceArr)
            setBlogData(sliceArr)
          }
        })
        .catch(e => console.log("Oops, error", e))
    }
    const getData_total_data = async () => {
      fetch(api_total_data, { method: 'get', })
        .then(e => e.json())
        .then((res) => {
          if (res) {
            // console.log(res)
            setTotalData(res)
          }
        })
        .catch(e => console.log("Oops, error", e))
    }

    getData()
    getData_total_data()
  }, [])


  const showMenu = () => {
    setMenu(!menu)
  }

  const rederTime = (stringTime) => {
    try {
      return (stringTime.split('T')[0] + ' ' + stringTime.split('T')[1])
    } catch (error) {
      return (stringTime)
    }
  }

  function decodeHtmlEntities(text) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const OutLink = {
    stake_now: 'https://app.aspidanet.com/',
    Documents: 'https://docs.aspidanet.com/',
    Blog: 'https://blog.aspidanet.com/',
    Twitter: 'https://twitter.com/aspidanet',
    Discord: 'https://discord.com/invite/UrYn3KjagY',
    Telegram: 'https://t.me/aspida_net',
    SeeReport: 'https://github.com/aspidanet/documents/tree/main/audits',
    Github: 'https://github.com/aspidanet',
    Medium: 'https://medium.com/@aspidabd',
    Forum: 'https://forum.aspidanet.com',
    BugBounty:'https://immunefi.com/bounty/aspida',
    FAQ: ''
  }

  // const buttonOnBlur = () => {
  //   setTimeout(() => {
  //     setOpen(false)
  //   }, 100)
  // }

  const repalceHtmlToText = (str) => {
    try {
      str = str.replace(/<\/?.+?>/g, "")
      str = str.replace(/&nbsp;/g, "")
      str = str.replace(/\n/g, "")
      // console.log(str)
      return str
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      {/* <Container>
        <Snowflakes className="wrapper"></Snowflakes>

        <TopBar>
          <Log src="/images/logo.svg" />
          <Nav>
            <li>
              <a href={OutLink.Documents} target="_blank" rel="noreferrer">Documents</a>
            </li>
            <li>
              <a href={OutLink.FAQ} target="_blank" rel="noreferrer">FAQ</a>
            </li>
            <li>
              <a href={OutLink.Blog} target="_blank" rel="noreferrer">Blog</a>
            </li>
            <ChangeLanguage
            onBlur={() => { buttonOnBlur() }}
            onClick={() => { setOpen(!open) }}
            >
              <span>Community</span>
              <Changelist className={'showList'}>
                <a href={OutLink.Telegram} target="_blank" rel="noopener noreferrer">Telegram</a>
                <a href={OutLink.Discord} target="_blank" rel="noopener noreferrer">Discord</a>
              </Changelist>
            </ChangeLanguage>
            <JoinT>
              <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">
                <img src="/images/Join-Twitter.svg" alt="" />
                <span>Join Twitter</span>
              </a>
            </JoinT>
          </Nav>
        </TopBar>
        <MtopBar>
          <MLeft>
            <Log src="/images/logo.svg" />
          </MLeft>
          <MRight>
            <JoinT>
              <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">
                <img src="/images/Join-Twitter.svg" alt="" />
                <span>Join Twitter</span>
              </a>
            </JoinT>
            <Menu onClick={showMenu}>
              <img src="/images/m-menu.svg" alt="" />
            </Menu>
          </MRight>
        </MtopBar> */}


        <Header>
          <Slogan>
            <SloLeft>
              <h1>{'Aspida'}</h1>
              <h3>{fmt({ id: 'Aspida_subTitle' })}</h3>
              <a href={OutLink.stake_now} target='_blank' rel="noreferrer">
                <span>
                Launch App
                </span>
                <img src="/images/arrow-right.svg" alt="" />
              </a>
            </SloLeft>
            <SloDate>
              <img src="/images/slogon.svg" />
            </SloDate>
          </Slogan>
        </Header>

        <StatisticsWrap>
          <Statistics>
            {/* <DataItem>
              <TotalValue>
                {totalData.totalMintedTokensAmount ? '$' + format_num_to_K(totalData.totalMintedTokensAmount) : '...'}
              </TotalValue>
              <ValueText>Total Minted Tokens</ValueText>
            </DataItem> */}
            <DataItem>
              <TotalValue>
                {totalData.totalMintedTokensAmount ? '$' + formatNumber(Number(totalData.totalMintedTokensAmount),4) : '...'}
              </TotalValue>
              <ValueText>Total staked</ValueText>
            </DataItem>
            <DataItem>
              <TotalValue>
                {totalData.apr ? totalData.apr : '...'}
              </TotalValue>
              <ValueText>
                      
                      <Tooltip
                      position="top"
                      trigger="hover"
                      content="Moving average of APR for 7 days period."
                    >
                      <div>APR</div>
                      <img src="/images/tooltip.svg" alt='' />
                    </Tooltip>
              </ValueText>
            </DataItem>
            <DataItem>
              <TotalValue>
                {/* {totalData.totalEigenlayerPoints ? '$' + format_num_to_K(totalData.totalEigenlayerPoints) : '...'} */}
                {totalData.totalEigenlayerPoints ? formatNumber(Number(totalData.totalEigenlayerPoints),2) : '...'}
              </TotalValue>
              <ValueText>Eigenlayer points</ValueText>
            </DataItem>
          </Statistics>
        </StatisticsWrap>

        <Plate>
          <Title>{fmt({ id: 'Features' })}</Title>
          <SecuritSubTitle>
            {fmt({ id: 'Features_SubTitle' })}
          </SecuritSubTitle>
          <PlateList>
            <HowItemTop>
              <img src="/images/Feature1.svg" />
              <h6>{fmt({ id: 'Feature1' })}</h6>
              <p>
                {fmt({ id: 'Feature1_text' })}
              </p>
              <a href="#" />
            </HowItemTop>
            <HowItemTop>
              <img src="/images/Feature2.svg" />
              <h6>{fmt({ id: 'Feature2' })}</h6>
              <p>
                {fmt({ id: 'Feature2_text' })}
              </p>
              <a className="hide" />
            </HowItemTop>
            <HowItemTop>
              <img src="/images/Feature3.svg" />
              <h6>{fmt({ id: 'Feature3' })}</h6>
              <p>
                {fmt({ id: 'Feature3_text' })}
              </p>
              <a className="hide" />
            </HowItemTop>
          </PlateList>
        </Plate>

        <Plate>
          <Title>{fmt({ id: 'Optimize_your_yield' })}</Title>
          <PlateList>
            <HowItem>
              <img src="/images/Stake-1.svg" />
              <h6>{fmt({ id: 'Stake' })}</h6>
              <p>
                {fmt({ id: 'Stake_text' })}
              </p>
            </HowItem>
            <HowItem>
              <img src="/images/Stake-3.svg" />
              <h6>{fmt({ id: 'Earn' })}</h6>
              <p>
                {fmt({ id: 'Earn_text' })}
              </p>
            </HowItem>
            <HowItem>
              <img src="/images/Stake-2.svg" />
              <h6>{fmt({ id: 'Use_in_DeFi' })}</h6>
              <p>
                {fmt({ id: 'Use_in_DeFi_text' })}
              </p>
            </HowItem>
          </PlateList>
        </Plate>

        <SecurityWrap>
          <Security>
            <SecurityLogo><img src="/images/logo.svg" alt="" /></SecurityLogo>
            <Title>{fmt({ id: 'SecurityAudit' })}</Title>
            <SecuritSubTitleSpe>{fmt({ id: 'SecurityAudit_sub' })}</SecuritSubTitleSpe>
            <LinkSee>
              <a href={OutLink.SeeReport} target="_blank" rel="noopener noreferrer">
                <span>See Report</span>
                <img src="/images/Arrow-outward.svg" alt="" />
              </a>
            </LinkSee>
            <ReportWrap>
              <ReportItem>
                <img src="/images/MixBytes().svg" alt="" />
              </ReportItem>
              {/* <ReportItem>
                <img src="/images/Audit-2.svg" alt="" />
              </ReportItem> */}
              <ReportItem>
                <img src="/images/Audit-3.svg" alt="" />
              </ReportItem>
            </ReportWrap>
          </Security>
        </SecurityWrap>

        <Join>
          <Title>{fmt({ id: 'Partners' })}</Title>
          <SecuritSubTitle>{fmt({ id: 'JoinUs_sub' })}</SecuritSubTitle>
          <PartnersBox>
            <PartnersItem>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src="/images/dForce.svg"/>
              </a>
            </PartnersItem>
            <PartnersItem>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src="/images/ssv.svg"/>
              </a>
            </PartnersItem>
            <PartnersItem>
              <a href="http://" target="_blank" rel="noopener noreferrer">
                <img src="/images/questn.svg"/>
              </a>
            </PartnersItem>
          </PartnersBox>
        </Join>

        <StakeWrap>
          <Stake>
            <StakeLeft>
              <Title className='left'>{fmt({ id: 'Stake_With_Aspida' })}</Title>
              <StakeSubTitle>{fmt({ id: 'Stake_With_Aspida_sub' })}</StakeSubTitle>
              <StakeBTN>
                <a href={OutLink.stake_now} target="_blank" rel="noopener noreferrer">
                  <span>Launch App</span>
                  <img src="/images/arrow-right.svg" alt="" />
                </a>
              </StakeBTN>
            </StakeLeft>
            <StakeRight>
              <img src="/images/Stake-with-aspida.svg" alt="" />
            </StakeRight>
          </Stake>
        </StakeWrap>

        <Join>
          <Title>{fmt({ id: 'JoinUs' })}</Title>
          {/* <SecuritSubTitle>{fmt({ id: 'JoinUs_sub' })}</SecuritSubTitle> */}
          <JoinBox>
            <JoinItem>
              <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">
                <JoinLogo>
                  <img src="/images/color-Twitter.svg" alt="" />
                </JoinLogo>
                <JoinRight>
                  <JoinTitle>Twitter</JoinTitle>
                  <JoinDetial>Follow @aspidanet</JoinDetial>
                </JoinRight>
              </a>
            </JoinItem>
            <JoinItem>
              <a href={OutLink.Telegram} target="_blank" rel="noopener noreferrer">
                <JoinLogo>
                  <img src="/images/color-Telegram.svg" alt="" />
                </JoinLogo>
                <JoinRight>
                  <JoinTitle>Telegram</JoinTitle>
                  <JoinDetial>Join the group</JoinDetial>
                </JoinRight>
              </a>
            </JoinItem>
            <JoinItem>
              <a href={OutLink.Discord} target="_blank" rel="noopener noreferrer">
                <JoinLogo>
                  <img src="/images/color-Discord.svg" alt="" />
                </JoinLogo>
                <JoinRight>
                  <JoinTitle>Discord</JoinTitle>
                  <JoinDetial>Join the community and ask questions</JoinDetial>
                </JoinRight>
              </a>
            </JoinItem>
            <JoinItem>
              <a href={OutLink.Github} target="_blank" rel="noopener noreferrer">
                <JoinLogo>
                  <img src="/images/color-Github.svg" alt="" />
                </JoinLogo>
                <JoinRight>
                  <JoinTitle>Github</JoinTitle>
                  <JoinDetial>Contribute</JoinDetial>
                </JoinRight>
              </a>
            </JoinItem>
          </JoinBox>
        </Join>

        <Blog>
          <Title>{fmt({ id: 'Blog' })}</Title>
          <SecuritSubTitle>{fmt({ id: 'Blog_sub' })}</SecuritSubTitle>
          <BlogBox>
            {
              BlogData && BlogData.length > 0 && BlogData.map((blogItem, index) => {
                return (
                  <BlogItem key={index}>
                    <BlogBanner blogItem={blogItem} />
                    <BlogTitle>
                      {decodeHtmlEntities(blogItem.title.rendered)}
                    </BlogTitle>
                    <BlogTime>
                      {blogItem.date && rederTime(blogItem.date)}
                    </BlogTime>
                    <BlogContext>
                      {/* {blogItem.content.rendered} */}
                      {decodeHtmlEntities(repalceHtmlToText(blogItem.content.rendered))}
                    </BlogContext>
                    <ReadMore>
                      <a href={blogItem.link} target="_blank" rel="noopener noreferrer">
                        <span>Read more</span>
                        <img src="/images/Arrow-outward.svg" alt="" />
                      </a>
                    </ReadMore>
                  </BlogItem>
                )
              })
            }
          </BlogBox>
        </Blog>

        <FooterTop>
          <ContentTop>
            <LeftLogo>
              <TmpTop>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img src="/images/logo.svg" alt="" />
                </a>
              </TmpTop>
              <TmpText>{fmt({ id: 'Aspida_footer_subTitle' })}</TmpText>
              <TmpMedia>
                <a href={OutLink.Twitter} target='_blank' rel="noreferrer">
                  <img src="/images/link/Twitter.svg" alt="Aspida Twitter" />
                </a>
                <a href={OutLink.Telegram} target='_blank' rel="noreferrer">
                  <img src="/images/link/Telegram.svg" alt="Aspida Telegram" />
                </a>
                <a href={OutLink.Discord} target='_blank' rel="noreferrer">
                  <img src="/images/link/Discord.svg" alt="Aspida Discord" />
                </a>
                <a href={OutLink.Medium} target='_blank' rel="noreferrer">
                  <img src="/images/link/Medium.svg" alt="Aspida Medium" />
                </a>
                <a href={OutLink.Github} target='_blank' rel="noreferrer">
                  <img src="/images/link/Github.svg" alt="Aspida Github" />
                </a>
              </TmpMedia>
            </LeftLogo>
            <RightMenuWrap>
              <RightMenu>
                <MenuTitle>Community</MenuTitle>
                <MenuLink>
                  <a href={OutLink.Twitter} target="_blank" rel="noopener noreferrer">Twitter</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Telegram} target="_blank" rel="noopener noreferrer">Telegram</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Discord} target="_blank" rel="noopener noreferrer">Discord</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Forum} target="_blank" rel="noopener noreferrer">Forum</a>
                </MenuLink>
                {/* <MenuLink>
                  <a href={OutLink.Medium} target="_blank" rel="noopener noreferrer">Medium</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Github} target="_blank" rel="noopener noreferrer">Github</a>
                </MenuLink> */}
              </RightMenu>

              <RightMenu>
                <MenuTitle>Product</MenuTitle>
                <MenuLink>
                  <a href={OutLink.Documents} target="_blank" rel="noopener noreferrer">Docs</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Github} target="_blank" rel="noopener noreferrer">Github</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.BugBounty} target="_blank" rel="noopener noreferrer">Bug Bounty</a>
                </MenuLink>
              </RightMenu>

              <RightMenu>
                <MenuTitle>News</MenuTitle>
                {/* <MenuLink>
                  <a href={OutLink.Documents} target="_blank" rel="noopener noreferrer">Docs</a>
                </MenuLink> */}
                <MenuLink>
                  <a href={OutLink.Blog} target="_blank" rel="noreferrer">Blog</a>
                </MenuLink>
              </RightMenu>

              {/* <RightMenu>
                <MenuTitle>About</MenuTitle>
                <MenuLink>
                  <a href={OutLink.Blog} target="_blank" rel="noopener noreferrer">Blog</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.FAQ} target="_blank" rel="noopener noreferrer">FAQ</a>
                </MenuLink>
                <MenuLink>
                  <a href={OutLink.Forum} target="_blank" rel="noopener noreferrer">Forum</a>
                </MenuLink>
              </RightMenu> */}
            </RightMenuWrap>
          </ContentTop>
        </FooterTop>

        {/* <Footer>
          <Content>
            <dl className="friendLink">
              <dd>
                <a href={OutLink.Twitter} target='_blank' rel="noreferrer">
                  <img src="/images/link/Twitter.svg" alt="Aspida Twitter" />
                </a>
                <a href={OutLink.Telegram} target='_blank' rel="noreferrer">
                  <img src="/images/link/Telegram.svg" alt="Aspida Telegram" />
                </a>
                <a href={OutLink.Discord} target='_blank' rel="noreferrer">
                  <img src="/images/link/Discord.svg" alt="Aspida Discord" />
                </a>
                <a href={OutLink.Medium} target='_blank' rel="noreferrer">
                  <img src="/images/link/Medium.svg" alt="Aspida Medium" />
                </a>
                <a href={OutLink.Github} target='_blank' rel="noreferrer">
                  <img src="/images/link/Github.svg" alt="Aspida Github" />
                </a>


                <a>
                  <img src="/images/link/icon-wechat.svg" alt="Aspida wechat" />
                  <img
                    className="qrCode"
                    src="/images/link/qrcode.svg"
                    alt="Aspida wechat"
                  />
                </a>
              </dd>
            </dl>
          </Content>
        </Footer> */}
      {/* </Container> */}
    </>
  )
}
export default Home

const linear_gradient = 'linear-gradient(113deg, #FB7C56 1.48%, #FF3585 100%)'

const StatisticsWrap = styled.div`
  width: 100%;
  /* background-color: red; */
  /* background: #1C1C20; */
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  padding: 32px 0;
  margin-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100vw;
    padding: 12px 0;
    margin-bottom: 16px;
  }
`
const Statistics = styled.div`
  width: 1200px;
  /* background-color: red; */
  justify-content: space-around;
  display: flex;
  @media (max-width: 1200px) {
    width: 100vw;
    padding: 0 16px;
  }
`
const DataItem = styled.div`
  /* background-color: red; */
`
const TotalValue = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: 1200px) {
    margin-bottom: 4px;
    font-size: 16px;
  }
`
const ValueText = styled.div`
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  @media (max-width: 1200px) {
    font-size: 10px;
  }
`

const ReportWrap = styled.div`
  width: 1200px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 72px;
  padding-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100vw;
    padding-top: 24px;
    padding-bottom: 32px;
  }
`
const ReportItem = styled.div`
  img {
    height: 40px;
    @media (max-width: 1200px) {
      height: 29px;
    }
  }
`
const LinkSee = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  a {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid transparent;
    position: relative;
    background-color: #03000A;
    background-clip: padding-box;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background: ${linear_gradient};
    }

    span {
      background: ${linear_gradient};
      background-clip: text; 
      -webkit-background-clip: text; 
      -webkit-text-fill-color: transparent;
    }
    img {
      margin-left: 8px;
    }
    @media (max-width: 1200px) {
      font-size: 11px;
    }
  }
  @media (max-width: 1200px) {
    padding-top: 12px;
  }
`
const SecurityWrap = styled.div`
  width: 100%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  position: relative;
`
// const BGgif = styled.div`
//   width: 1200px;
//   height: 100%;
//   position: absolute;
//   img {
//     width: 100%;
//     height: 100%;
//   }
// `
const Security = styled.div`
  width: 1200px;
  /* background-color: red; */
  margin-top: 72px;
  margin-bottom: 72px;
  z-index: 2;
  @media (max-width: 1200px) {
    width: 100vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`
const SecurityLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  @media (max-width: 1200px) {
    margin-bottom: 16px;
    img {
      width: 100px;
    }
  }
`

const StakeWrap = styled.div`
  width: 100%;
  /* background-color: red; */
  background: #1C1C20;
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100vw;
    padding: 16px 0px;
    margin-bottom: 16px;
  }
`
const Stake = styled.div`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100vw;
    padding: 0 16px;
  }
`
const StakeLeft = styled.div`
`
const StakeSubTitle = styled.div`
  color: rgba(255, 255, 255, 0.60);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 10px;
  }
`
const StakeBTN = styled.div`
  margin-top: 24px;
  border-radius: 8px;
  width: 140px;
  background: ${linear_gradient};
  a {
    display: flex;
    padding: 8px 12px;
    align-items: center;
  }
  img {
    width: 14px;
    margin-left: 8px;
  }
  span {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: 1200px) {
    display: flex;
    font-size: 11px;
    margin-top: 16px;
    height: 26px;
    width: 90px;
    a {
      padding: 0;
      width: 100%;
      justify-content: center;
    }
    img {
      width: 12px;
      margin-left: 4px;
    }
    span {
      font-size: 11px;
    }
  }
`
const StakeRight = styled.div`
  img {
    @media (max-width: 1200px) {
      height: 100px;
    }
  }
`


const Join = styled.div`
  width: 1200px;
  /* background-color: red; */
  padding-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100vw;
    padding-bottom: 16px;
  }
`
const JoinBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;

  @media (max-width: 1200px) {
    width: 100vw;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
`
const PartnersBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 32px;
  gap: 15px;
  @media (max-width: 1200px) {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100vw;
    justify-content: space-around
  }
`
const PartnersItem = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  /* width: 282px;
  height: 72px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #1C1C20;
  margin-right: 24px;
  margin-bottom: 24px;
  &:nth-child(4n) {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-right: 0;
    width: 165px;
    height: 42px;
    margin-bottom: 12px;
  } */
`
const JoinItem = styled.div`
  a {
    display: flex;
    display: flex;
    width: 588px;
    padding: 24px;
    align-items: flex-start;
    border-radius: 16px;
    background: #1C1C20; 
    margin-bottom: 24px;
    @media (max-width: 1200px) {
      width: 100%;
      padding: 12px;
      margin-bottom: 12px;
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`
const JoinLogo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
`
const JoinRight = styled.div`
  margin-left: 16px;
`
const JoinTitle = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
`
const JoinDetial = styled.div`
  color: rgba(255, 255, 255, 0.60);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`


const Blog = styled.div`
  width: 1200px;
  /* background-color: red; */
  padding-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100vw;
    padding-bottom: 16px;
  }
`
const BlogBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  align-items:center;
  width: 100%;
  padding-top: 32px;
  @media (max-width: 1200px) {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
`
const BlogItem = styled.div`
  width: 384px;
  border-radius: 16px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`
const BlogTitle = styled.div`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 1200px) {
    padding-top: 12px;
    font-size: 16px;
  }
`
const BlogTime = styled.div`
  color: rgba(255, 255, 255, 0.60);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 4px;
  @media (max-width: 1200px) {
    font-size: 11px;
  }
`
const BlogContext = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-top: 16px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 1200px) {
    font-size: 13px;
    line-height: 16px;
    padding-top: 8px;
  }
`
const ReadMore = styled.div`
  a {
    padding-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: ${linear_gradient};
    background-clip: text; 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    display: flex;
    @media (max-width: 1200px) {
      font-size: 11px;
      padding-top: 12px;
    }
  }

  img {
    width: 10px;
    margin-left: 8px;
    @media (max-width: 1200px) {
      width: 8px;
      margin-left: 4px;
    }
  }

  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
`


const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  background: url('/images/body-bg.svg') no-repeat center top;
  @media (max-width: 1200px) {
    width: 100vw;
    height: auto;
    position: inherit;
    left: 0;
    transform: translateX(0);
    background-size: 170%;
  }
`;
// const Mheader = styled.section`
//   display: none;
//   @media (max-width: 1200px) {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     width: 100%;
//     height: 580px;
//     color: #fff;
//   }
// `;
const MtopBar = styled.header`
  display: none;
  @media (max-width: 1200px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 12px;
  }
`;
const MLeft = styled.header`
  @media (max-width: 1200px) {
    img {
      height: 30px;
      width: 100px;
    }
  }
`;
const MRight = styled.header`
  @media (max-width: 1200px) {
    display: flex;
  }
`;
const Menu = styled.div`
  width: 26px;
  height: 26px;
  margin-left: 8px;
  /* background-color: red; */
  img {
    width: 26px;
    height: 26px;
  }
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    background-color: #1C1C20;
    width: 140px;
    position: absolute;
    right: 12px;
    top: 40px;
    padding: 4px 16px;
    border-radius: 8px;
    a {
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1200px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #00000099;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 72px;
  color: #fff;
  @media (max-width: 1200px) {
    padding-bottom: 16px;
    padding-top: 0px;
  }
`;
const JoinT = styled.div`
  border-radius: 8px;
  background: ${linear_gradient};
  a {
    display: flex;
    padding: 8px 12px;
    align-items: center;
  }
  img {
    width: 14px;
    margin-right: 8px;
  }
  span {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: 1200px) {
    a {
      padding: 6px 12px;
    }
    img {
      width: 10px;
      margin-right: 4px;
    }
    span {
      font-size: 11px;
    }
  }
`;
const TopBar = styled.header`
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  /* background-color: red; */
  @media (max-width: 1200px) {
    display: none;
  }
`;
const Log = styled.img`
  width: 157px;
  height: 31px;
  @media (max-width: 1200px) {
    width: 78px;
    height: 15px;
  }
`;
const ChangeLanguage = styled.button`
  cursor: pointer;
  margin-right: 24px;
  position: relative;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  position: absolute;
  left: 50%;
  top: 36px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background-color: #000;
  border-radius: 8px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  a {
    line-height: 36px;
  }
`;
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  li {
    margin-right: 24px;
    display: flex;
    align-items: center;
    a {
      color: #fff;
    }
  }
`;
const Slogan = styled.article`
  width: 1200px;
  height: 470px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  @media (max-width: 1200px) {
    width: 100%;
    height: 150px;
    padding: 0 16px;
    margin:20px 0 10px;
    /* background-color: red; */
  }
`;
// const Circle = styled.div`
//   width: 86px;
//   height: 86px;
//   background: url('/images/circle.svg') no-repeat;
//   background-position: center;
//   background-size: 86px 86px;
//   position: absolute;
//   left: -20px;
//   top: -17px;
//   z-index: -1;
//   @media (max-width: 1200px) {
//     width: 50px;
//     height: 50px;
//     background: url('/images/circle.svg') no-repeat;
//     background-position: center;
//     background-size: 50px 50px;
//     position: absolute;
//     left: -9px;
//     top: -6px;
//   }
// `
const SloLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 640px;
  height: 300px;
  h1 {
    color: #FFF;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h3 {
    width: 560px;
    margin-bottom: 56px;
    color:#fff;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 48px;
    cursor: pointer;
    background: ${linear_gradient};
    border-radius: 8px;
    padding: 12px 24px;
    img {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
  };
  @media (max-width: 1200px) {
    width: 210px;
    /* background-color: pink; */
    height: auto;
    h1 {
      font-size: 28px;
      margin-bottom: 8px;
    }
    h3 {
      font-size: 12px;
      margin-bottom: 28px;
      width: auto;
    }
    a {
      font-size: 11px;
      width: 120px;
      height: 26px;
      padding: 0;
      img {
        width: 12px;
        height: 12px;
        margin-left: 8px;
      }
    };
  }
`;
const SloDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  height: 470px;
  img {
    height: 100%;
  }

  @media (max-width: 1200px) {
    width: 150px;
    height: 100%;
    justify-content: center;
    img{
      width: 100%;
    }
  }
`;
const Plate = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  margin-bottom: 72px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;
  }
`;
const Title = styled.h1`
  color: #fff;
  text-align:center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  @media (max-width: 1200px) {
    font-size: 20px;
    font-weight: 600;
  }
  &.left {
    text-align: left;
  }
`;
const SecuritSubTitle = styled.h3`
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  width: 750px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    font-size: 10px;
    width: auto;
    padding: 0 16px;
  }
`
const SecuritSubTitleSpe = styled.h3`
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  width: 426px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    font-size:10px;
    width: auto;
  }
`
const PlateList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  padding-top: 40px;
  @media (max-width: 1200px) {
    padding-top: 12px;
  }
`;
const HowItemTop = styled.li`
  border-radius: 16px;
  background-color: rgba(22,20,28,0.8);
  width: 384px;
  padding: 24px;
  img {
    height: 83px;
    margin-bottom:16px;
  }
  h6 {
    color: #fff;
    margin-bottom: 12px;
    font-style: normal;
    line-height: normal;
    font-size: 18px;
    font-weight: 700;
  }
  p {
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 12px;
    padding: 12px;
    img {
      height: 58px;
      margin-bottom: 8px;
    }
    h6 {
      margin-bottom: 12px;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
const HowItem = styled.li`
  border-radius: 16px;
  /* background-color: rgba(22,20,28,0.8); */
  width: 384px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  img {
    height: 154px;
    margin-bottom:16px;
  }
  h6 {
    color: #fff;
    margin-bottom: 12px;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  @media (max-width: 1200px) {
    padding: 12px;
    width: 100%;
    /* margin-bottom: 10px; */
    img {
      height: 93px;
      margin-bottom: 12px;
    }
    h6 {
      margin-bottom: 6px;
      font-size: 14px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
const Footer = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-top:1px solid #1c1c1c;
  background: rgba(28, 28, 32, 0.32);
  @media (max-width: 1200px) {
    width: 100vw;
  }
`;
const Content = styled.section`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 1200px;
  height: 80px;
  dl {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  dt {
    font-size: 20px;
  }
  dd {
    font-size: 14px;
    opacity: 0.7;
  }
  .friendLink {
    dd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      height: 80px;
      /* margin: 20px -50px 0 0; */
      /* width: 330px;
      height: 60px; */
      a {
        display: block;
        position: relative;
        margin:0 8px;
        img {
          width: 32px;
          height: 32px;
        }
        /* .qrCode {
          width: 120px;
          height: 120px;
          display: none;
          position: absolute;
          left: 50%;
          top: -130px;
          transform: translateX(-50%);
        }
        &:hover {
          .qrCode {
            display: block;
          }
        } */
      }
    }
  }
  @media (max-width: 1200px) {
    width: 100%;
    height: 56px;
    padding: 16px 16px;
    flex-wrap: wrap;
    dl {
      width:100%;
      line-height: 25px;
    }
    dt {
      font-size: 14px;
    }
    dd {
      height: 56px;
      font-size: 12px;
      width: 100%;
      flex-wrap: nowrap;
      flex-direction: row;
    }
    .friendLink {
      dd {
        width: 100%;
        height: 56px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        /* margin: 25px -35px 0 0; */
        /* width: 260px; */
        a {
          margin:0;
          margin-right: 12px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
`;



const FooterTop = styled.footer`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-top:1px solid #1c1c1c;
  background: rgba(28, 28, 32, 0.32);
  @media (max-width: 1200px) {
    width: 100vw;
  }
`
const ContentTop = styled.section`
  display: flex;
  width: 1200px;
  padding-top: 40px;
  padding-bottom: 16px;
  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 0px;
  }
`
const LeftLogo = styled.section`
  margin-right: 240px;
  @media (max-width: 1200px) {
    margin-right: 0px;
    margin-bottom: 16px;
  }
`
const TmpTop = styled.section`
  img {
    @media (max-width: 1200px) {
      height: 22px;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 12px;
  }
`
const TmpText = styled.section`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 32px;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`
const TmpMedia = styled.section`
  display: flex;
  a {
    display: block;
    position: relative;
    margin-right:16px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 1200px) {
    a {
      margin:0;
      margin-right: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`
const RightMenuWrap = styled.section`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
const RightMenu = styled.section`
  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
`
const MenuTitle = styled.section`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 10px;
  @media (max-width: 1200px) {
    font-size: 16px;
    padding-bottom: 4px;
  }
`
const MenuLink = styled.section`
  a {
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    transition: 0.3s;
    &:hover {
      color: #ffffff;
    }
    @media (max-width: 1200px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
`
