/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

const Shields = ({ changeLanguage, locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const handleClick = (e) => {
    const currentIndex = e.currentTarget.dataset.index;
    // e.currentTarget.classList.add("open")
    e.currentTarget.classList.contains("open") ?
      e.currentTarget.classList.remove("open")
      : e.currentTarget.classList.add("open")
    console.log(e.currentTarget.dataset.index)
    // console.log(e.currentTarget.classList)
  }
  return (
    <>
      <SloganWarp>
        <Slogan>
          <Title className='left'>{fmt({ id: 'sloganTitle' })}</Title>
          <SubTitle>{fmt({ id: 'sloganSubTitle' })}</SubTitle>
          <BTNWarp>
            <StakeBTN>
              <a href='https://app.aspidanet.com/' target="_blank" rel="noopener noreferrer">
                <span>Stake Now</span>
                <img src="/images/arrow-right.svg" alt="" />
              </a>
            </StakeBTN>
            <StakeBTN className='LearnMore'>
              <a href='https://blog.aspidanet.com/legendary-journey-aspidas-points-campaign-for-mainnet-launch.html' target="_blank" rel="noopener noreferrer">
                <span>Learn Rules</span>
              </a>
            </StakeBTN>
          </BTNWarp>
        </Slogan>
      </SloganWarp>
      <Enlistment>
        <Topic>
          {/* <EnTitleWarp> */}
            {/* <EnBtn>Chapter one</EnBtn> */}
            <EnTitle>Campaign Tasks</EnTitle>
          {/* </EnTitleWarp> */}
          <EnSubTitle>
          You can complete tasks to maximize your Shields collection! The Shields you hold will be utilized in Aspida's future developments and share the upside of Aspida network.
          </EnSubTitle>
        </Topic>
        <Soldier>
          <SoTitle>
            1.&nbsp;Invite Friends
          </SoTitle>
          <SoInfo>
            <SoLeft>
              <SlTitle>Inviting Your Friends to Join Aspida via Exclusive Invitation Links</SlTitle>
              <SlSubTitle>Generate an exclusive invitation link by connecting your wallet, become an Aspida Community Ambassador, share the link through social platforms such as Discord, Telegram, or X, and invite your friends to complete staking to receive rewards.</SlSubTitle>
              {/* <StakeBTN>
                <a target="_blank" rel="noopener noreferrer">
                  <span>Mission</span>
                  <img src="/images/arrow-up.svg" alt="" />
                </a>
              </StakeBTN> */}
            </SoLeft>
            <img className='pic' src="/images/Recruitment.svg" />
          </SoInfo>
          <SoTitle>
            2.&nbsp;Deposit
          </SoTitle>
          <SoInfo>
            <SoLeft>
              <SlTitle>Forging saETH via (wst)ETH Deposit with Aspida-dForce Vault</SlTitle>
              <SlSubTitle>Aspida and dForce have collaborated to create a joint vault on Arbitrum for participants to deposit ETH or wstETH to participate in saETH forging upon its launch on Arbitrum. The campaign will kick off on February 1, 2024 for a duration of 38 days.</SlSubTitle>
              <img className='predeposit' src="/images/predeposit.svg" alt='' />
              <StakeBTN>
                <a href='https://blog.aspidanet.com/forging-saeth-via-aspida-dforce-lsd-vault.html' target="_blank" rel="noopener noreferrer">
                  <span>More</span>
                  {/* <img src="/images/arrow-up.svg" alt="" /> */}
                </a>
              </StakeBTN>
            </SoLeft>
            <img className='pic' src="/images/Training.svg" />
          </SoInfo>
        </Soldier>
        <ColTitle>FAQ</ColTitle>
        <Collapse>
          <Item data-index="1" onClick={(e) => { handleClick(e) }}>
            <ItemHeader><Text>What is Legendary?</Text><Icon /></ItemHeader>
            <ItemContent>
              The Legendary Journey is a points-based campaign prepared for the Aspida mainnet launch, comprising a series of tasks, allowing participants to earn Aspida Points(Shields).
            </ItemContent>
          </Item>
          <Item data-index="2" onClick={(e) => { handleClick(e) }}>
            <ItemHeader><Text>What are Shields?</Text><Icon /></ItemHeader>
            <ItemContent>Each Shield represents a share of the Aspida Airdrop, which will ultimately provide the holder with governance tokens after the TGE(Token Generation Event). Governance rights will be distributed to early community members.</ItemContent>
          </Item>
          <Item data-index="3" onClick={(e) => { handleClick(e) }}>
            <ItemHeader><Text>How do I collect Shields?</Text><Icon /></ItemHeader>
            <ItemContent>
            In this campaign, Shields can be obtained by holding saETH or serving as an Aspida Promotional Ambassador:<br/>
            1. Inviting Friends to join Aspida via Exclusive Invitation Links.<br/>
            2. Depositing ETH or wstETH via Aspida-dForce LSD Vault.<br/>
            In the future, we plan to release additional tasks, such as expanding the utility of saETH in DeFi, GameFi, or NFT, to create more opportunities for collecting Shields.
            </ItemContent>
          </Item>
          <Item data-index="4" onClick={(e) => { handleClick(e) }}>
            <ItemHeader><Text>How are Shields' Reward Calculated?</Text><Icon /></ItemHeader>
            <ItemContent>
            Shields are calculated based on.<br/>
            1. the amount of eligible tokens participants hold.<br/>
            2. the duration participants hold.<br/>
            </ItemContent>
          </Item>
          <Item data-index="5" onClick={(e) => { handleClick(e) }}>
            <ItemHeader><Text>What is the Best Way to Stay Up-to-date with the Legendary?</Text><Icon /></ItemHeader>
            <ItemContent>
            To stay fully informed about the campaign, we encourage you to actively engage in our <a className='link' href='https://discord.com/invite/UrYn3KjagY' target="_blank" rel="noopener noreferrer">Discord</a> community and follow our updates on <a className='link' href='https://t.me/aspida_net' target="_blank" rel="noopener noreferrer">X</a>.<br/>
            By joining the conversation on <a className='link' href='https://discord.com/invite/UrYn3KjagY' target="_blank" rel="noopener noreferrer">Discord</a>, you can participate in discussions, receive real-time updates, and connect with fellow community members. Additionally, following us on <a className='link' href='https://t.me/aspida_net' target="_blank" rel="noopener noreferrer">X</a> will provide you with the latest news, announcements, and insights into the ongoing developments of the campaign.
            </ItemContent>
          </Item>
        </Collapse>
      </Enlistment>
    </>
  )
}
export default Shields

const linear_gradient = 'linear-gradient(90deg, #FB7C56 1.48%, #FF3585 100%)';
const SloganWarp = styled.article`
  width: 100%;
  padding:74px 0;
  margin-top: 70px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
    margin: 0;
    /* background-color: red; */
  }
`;
const Slogan = styled.div`
  width: 1200px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  @media (max-width: 1200px) {
    width: 100%;
    padding: 25px 16px 16px;
    margin:0;
    gap: 12px;
    /* background-color: red; */
  }
`;
const Title = styled.h1`
  color: #fff;
  text-align:center;
  font-size: 60px;
  font-weight: 500;
  line-height: 75px;
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    padding:0 42px;
  }
`;
const SubTitle = styled.h3`
  max-width: 880px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 20px;
  }
`
const BTNWarp = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`
const StakeBTN = styled.div`
  width: fit-content;
  border-radius: 8px;
  padding: 12px 24px;
  background: ${linear_gradient};
  &.LearnMore{
    background-clip: padding-box,border-box;
    background-image: linear-gradient(to right,#040607,#040607),${linear_gradient};
    background-origin: padding-box,border-box;
    border: 1px solid transparent;
    span{
      background: ${linear_gradient};
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  a {
    display: flex;
    /* padding: 12px 12px; */
    align-items: center;
    justify-content: center;
  }
  img {
    width: 14px;
    margin-left: 8px;
  }
  span {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @media (max-width: 1200px) {
    display: flex;
    font-size: 11px;
    margin-top: 6px;
    padding: 12px 24px;
    /* height: 26px;
    width: 90px; */
    a {
      padding: 0;
      width: 100%;
      justify-content: center;
    }
    img {
      width: 12px;
      margin-left: 4px;
    }
    span {
      font-size: 11px;
    }
  }
`
const Enlistment = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  gap: 40px;
  margin:64px 0;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 16px;
    gap: 20px;
    margin:44px 0;
  }
`
const Topic = styled.article`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 8px;
  }
`
const EnTitleWarp = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #fff;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
const EnBtn = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 6px 8px;
  gap: 10px;
  border-radius: 4px;
  background: ${linear_gradient};
  @media (max-width: 1200px) {
    font-size: 14px;
    padding: 4px 6px;
  }
`
const EnTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 20px;
  }
`
const EnSubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  @media (max-width: 1200px) {
    font-size: 10px;
    line-height: 18px;
  }
`
const Soldier = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 12px;
  }
`
const SoTitle = styled.article`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  background: ${linear_gradient};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding-left: 26px;
  &::before{
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${linear_gradient};
    position: absolute;
    left: 0;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 20px;
    padding-left: 14px;
    &::before{
      width: 8px;
      height: 8px;
    }
  }
`
const SoInfo = styled.article`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  gap: 32px;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 12px;
    gap: 16px;
    .pic{
      width: 125px;
    }
  }
`
const SoLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #fff;
  .predeposit{
    width: 283px;
  }
  @media (max-width: 1200px) {
    .predeposit{
      width: 177px;
    }
  }
`
const SlTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  @media (max-width: 1200px) {
    font-size: 14px;
    line-height: 17px;
  }
`
const SlSubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 18px;
  }
`
const ColTitle = styled.div`
font-size: 32px;
font-weight: 700;
line-height: 40px;
color: #fff;
@media (max-width: 1200px) {
  font-size: 16px;
  line-height: 20px;
}
`
const Collapse = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1200px) {
    gap: 12px;
  }
`
const Item = styled.li`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #FFFFFF14;
  gap: 8px;
  @media (max-width: 1200px) {
    padding: 12px;
    gap: 0;
  }
`
const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  font-size: 16px;
  font-weight: 600px;
  color: #fff;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`
const Icon = styled.div`
  &:after {
    content: "";
    display: flex;
    align-items: center;
    width: 8px;
    height: 8px;
    /* margin: 0 5px 2px 5px; */
    transform: rotate(135deg);
    transform-origin: center;
    transition: all ease-in-out 0.2s;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
  }
  .open & {
    &:after {
      transform: rotate(-45deg);
      transform-origin: center;
      margin: 7px 5px 0 5px;
      transition: all ease-in-out 0.2s;
    }
  }
`
const ItemContent = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #FFFFFF99;
  height: 0; 
  visibility: hidden;
  .open & {
    height: auto;
    visibility: visible;
  }
  @media (max-width: 1200px) {
    margin-top: 5px;
    font-size: 12px;
    line-height: 20px;
  }
`
