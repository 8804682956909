export const format_num_to_K = (str_num) => {
  var part_a = str_num.split(".")[0]
  var part_b = str_num.split(".")[1]

  var reg = /\d{1,3}(?=(\d{3})+$)/g
  part_a = (part_a + "").replace(reg, "$&,")

  return (part_b ? part_a + "." + part_b : part_a)
}
// 显示千分位 不进位
export const formatNumber = (num, point) => {
  try {
    num = num?.toString()
    let reg = num.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g
    // return num.replace(reg, '$1,').replace(/(?<=\.\d{0}).*$/, '')
    switch (point) {
      case 2:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '')
        break;
      case 4:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{4}).*$/, '')
        break;
      case 6:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{6}).*$/, '')
        break;
      case 8:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{8}).*$/, '')
        break;
      default:
        return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '')
        break;
    }
  } catch (error) {
    console.log(error)
  }

}
